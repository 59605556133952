



















































































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Paginate from 'vuejs-paginate'
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    Paginate,
  },
})
export default class ChatList extends Vue {
  LOAD_COUNT = 30
  GET_MAX_PAGE = 5
  isLoading = true
  searchValue = ''
  rooms: {id: string, onayamiId: string, name: string, title: string, clinic: boolean, status: string, chat: string, chatNum: number, date: string, uids: string[], isClinic: boolean}[] = []
  viewRooms: {id: string, onayamiId: string, name: string, title: string, clinic: boolean, status: string, chat: string, chatNum: number, date: string, uids: string[], isClinic: boolean}[] = []
  viewTab = false
  status = ''
  position = ''
  alreadyReadResult: any = ''
  unsubscribe: any
  page = 1
  pages = 1

  clickCallback(pageNum: number) {
    this.isLoading = true
    this.page = pageNum
    this.$router.replace({name: 'chatlist', query: {page: String(pageNum)}})
    
    this.viewRooms = []
    let promises: any[] = []
    let uid = Common.getUserId()

    // 二つ先のpageのデータがない場合は取得
    if (this.alreadyReadResult && (pageNum + 2) * this.LOAD_COUNT > this.rooms.length) {
      promises.push(new Promise((resolve) => {
        this.query(this.alreadyReadResult).then((data) => {
          this.setRoom(data.docs, this.pages)
          resolve()
        })
      }))
    }
    // 二つ前のpageのデータがない場合は取得
    else if (pageNum > this.GET_MAX_PAGE - 2 && (!this.rooms[pageNum - 2 * this.LOAD_COUNT - 1] || !this.rooms[pageNum - 2 * this.LOAD_COUNT - 1].id)) {
      let date: any
      let index = 0
      for (let i=0;!date;i++) {
        date = this.rooms[i] ? this.rooms[i].date : null
        index = i
      }
      
      index = index > this.GET_MAX_PAGE + 2 ? index - (this.LOAD_COUNT * this.GET_MAX_PAGE) : 0
      promises.push(new Promise((resolve) => {
        this.query(date, 0, true).then((data) => {
          this.setRoom(data.docs.reverse(), 0, index)
          resolve()
        })
      }))
    } else {
      // 指定されたpageのデータのみをviewRoomsに設定
      for (let i = (this.page - 1) * this.LOAD_COUNT; this.viewRooms.length < this.LOAD_COUNT; i++) {
        let room = this.rooms[i]
        if (!room) {
          break
        }
        this.viewRooms.push(room)
        let partnerUid = room.uids[0] == uid ? room.uids[1] : room.uids[0]
        this.getRoomInfo(promises, partnerUid, room)
      }
    }

    Promise.all(promises).finally(() => {
      let count = this.getLoadFirstCount()
      if (count == 0) {
        sessionStorage.setItem('chatSearch', JSON.stringify({status: this.status, position: this.position}))
      } else {
        sessionStorage.setItem('chatSearch', JSON.stringify({status: this.status, position: this.position, alreadyLoadCount: count, start: this.rooms[count].date}))
      }
      this.isLoading = false
    })
  }

  getLoadFirstCount() {
    if (this.page < this.GET_MAX_PAGE + 3) {
      return 0
    }
    let surplus = this.page % this.GET_MAX_PAGE
    let alreadyLoadPage
    if (surplus == 1) {
      alreadyLoadPage = this.page - this.GET_MAX_PAGE - surplus
    } else if (surplus == 2 || surplus == 3 || surplus == 4) {
      alreadyLoadPage = this.page - surplus
    } else {
      alreadyLoadPage = this.page - this.GET_MAX_PAGE
    }
    return alreadyLoadPage * this.LOAD_COUNT
  }

  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page)
    } else {
      sessionStorage.removeItem('chatSearch')
    }
    this.search(false, this.page)

    let uid = Common.getUserId()
    let oneMonthAgo = moment(new Date()).add(-30, 'days').toDate()
    this.unsubscribe = firebase.firestore().collectionGroup('rooms').where('uids', 'array-contains', Common.getUserId()).where('chatCreateTime', '>', oneMonthAgo).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          let id = change.doc.id
          let data = change.doc.data()!
          let partnerUid = data.uids[0] == uid ? data.uids[1] : data.uids[0]
          let existData = false
          for (let i=0; i< this.rooms.length; i++) {
            if (this.rooms[i].id == id) {
              this.rooms[i].chat = data.chatText
              this.rooms[i].status = data.status
              this.rooms[i].chatNum = data.chatUid != uid ? data.chatNum : 0
              this.rooms[i].chatNum += (this.rooms[i].isClinic ? data.clinicChatNum : data.studentChatNum)
              this.rooms[i].date = data.chatCreateTime ? data.chatCreateTime.toDate() : ''
              existData = true
              break
            }
          }
          if (!existData) {
            if (this.page == 1 && data.chatStart) {
              let obj = <any>{id: change.doc.id, onayamiId: change.doc.ref.parent.parent!.id, status: data.status, startTime: data.startTime, isClinic: data.clinic == uid, chatNum: data.chatUid != uid ? data.chatNum : 0, chat: data.chatText, date: (data.chatCreateTime ? data.chatCreateTime.toDate() : ''), uids: data.uids}
              obj.chatNum += (obj.isClinic ? data.clinicChatNum : data.studentChatNum)
              this.rooms.unshift(obj)
              this.viewRooms.unshift(obj)
              let promises: any[] = []
              this.getRoomInfo(promises, partnerUid, obj)
              Promise.all(promises)
            }
          }
        }
      })
    })
  }

  search(conditionChange: boolean, page?: number) {
    this.isLoading = true
    this.rooms = []
    this.viewRooms = []
    
    let session: any = sessionStorage.getItem('chatSearch')
    if (session) {
      session = JSON.parse(session)
    }
    let start: any
    let alreadyLoadCount: any
    let alreadyLoadPage: any = 0
    if (!conditionChange) {
      this.status = session && session.status ? session.status : this.status
      this.position = session && session.position ? session.position : this.position
      
      start = session && session.start ? new Date(session.start) : null
      alreadyLoadCount = session && session.alreadyLoadCount ? session.alreadyLoadCount : null
      alreadyLoadPage = Math.floor(alreadyLoadCount / this.LOAD_COUNT)
      if (alreadyLoadPage + this.GET_MAX_PAGE * 2 < this.page) {
        this.page = 1
        this.$router.replace({name: 'chatlist', query: {page: String(1)}})
        alreadyLoadCount = 0
        alreadyLoadPage = 0
      }
    }

    this.query(start, alreadyLoadCount).then((data) => {
      this.setRoom(data.docs, alreadyLoadPage)
    })
    if (conditionChange) {
      this.page = 1
      this.$router.replace({name: 'chatlist', query: {page: String(1)}})
      sessionStorage.setItem('chatSearch', JSON.stringify({status: this.status, position: this.position}))
    }
  }

  query(start?: any, noLoadCount?: number, beforeLoad = false) {
    let collection = firebase.firestore().collectionGroup('rooms').where('uids', 'array-contains', Common.getUserId()).where('chatStart', '==', true)
    if (this.status) {
      collection = collection.where('status', '==', this.status)
    }
    if (this.position) {
      if (this.position == "1") {
        collection = collection.where('clinic', '==', Common.getUserId())
      } else {
        collection = collection.where('patient', '==', Common.getUserId())
      }
    }

    if (beforeLoad) {
      collection = collection.orderBy('chatCreateTime').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
    } else if (start) {
      if (noLoadCount) {
        for (let i=0; i < noLoadCount;i++) {
          if (noLoadCount == i + 1) {
            this.rooms.push(<any>{date: start})
          } else {
            this.rooms.push(<any>null)
          }
        }
        collection = collection.orderBy('chatCreateTime', 'desc').startAt(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
      } else {
        collection = collection.orderBy('chatCreateTime', 'desc').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
      }
    } else {
      collection = collection.orderBy('chatCreateTime', 'desc').limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
    }
    return collection.get()
  }

  setRoom(data: firebase.firestore.QueryDocumentSnapshot[], pages?: number, unshiftIndex: any = null) {
    let promises:any = []
    let uid = Common.getUserId()
    data.forEach((room, index) => {
      let result = room.data()
      let isClinic = result.clinic == uid
      let partnerUid = result.uids[0] == uid ? result.uids[1] : result.uids[0]

      let obj = <any>{id: room.id, onayamiId: room.ref.parent.parent!.id, status: result.status, startTime: result.startTime, isClinic: isClinic, chatNum: result.chatUid != uid ? result.chatNum : 0, chat: result.chatText, date: (result.chatCreateTime ? result.chatCreateTime.toDate() : ''), uids: result.uids}
      obj.chatNum += (obj.isClinic ? result.clinicChatNum : result.studentChatNum)
      if (unshiftIndex || unshiftIndex == 0) {
        this.rooms[unshiftIndex++] = obj
      } else {
        this.rooms.push(obj)
      }
    })

    for (let i = (this.page - 1) * this.LOAD_COUNT; this.viewRooms.length < this.LOAD_COUNT; i++) {
      let room = this.rooms[i]
      if (!room) {
        break
      }
      let partnerUid = room.uids[0] == uid ? room.uids[1] : room.uids[0]
      this.viewRooms.push(room)
      this.getRoomInfo(promises, partnerUid, room)
    }

    if (!unshiftIndex && unshiftIndex != 0) {
      this.pages = pages ? (pages + Math.floor(data.length / this.LOAD_COUNT)) : data.length / this.LOAD_COUNT
      this.alreadyReadResult = (data.length == this.LOAD_COUNT * this.GET_MAX_PAGE || data.length == this.LOAD_COUNT * this.GET_MAX_PAGE * 2) ? this.rooms[this.rooms.length - 1].date : null
    }

    return Promise.all(promises).finally(() => {this.isLoading = false})
  }

  getRoomInfo(promises: any[], partnerUid: string, obj: any) {
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('users').doc(partnerUid).get().then((user) => {
        let userData = user.data()!
        this.$set(obj, 'name', userData.name)
        this.$set(obj, 'url', Common.createUserIconURL(partnerUid, userData.fileExist))
      }).finally(() => {
        resolve()
      })
    }))
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').doc(obj.onayamiId).get().then((onayami) => {
        this.$set(obj, 'title', onayami.data()!.title)
      }).finally(() => {
        resolve()
      })
    }))
  }

  select(room: firebase.firestore.DocumentData) {
    this.$router.push(`onayamis/${room.onayamiId}/chat/${room.id}`)
  }

  beforeDestroy() {
    this.unsubscribe ? this.unsubscribe() : null
  }
}
